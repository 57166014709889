<template>
  <div :class="[$style['logo'], {[$style['logo--loading']]: isLoading}]">
    <svg width="336" height="80" viewBox="0 0 336 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M289.623 37.8908V16.1066H307.144V21.2414H295.598V24.5402H306.054V29.3016H295.598V32.756H307.299V37.8908H289.623Z" :class="$style['logo__text']" />
      <path d="M277.88 38.2643C274.021 38.2643 270.348 37.0506 267.672 34.6543L271.033 30.6398C273.18 32.3514 275.577 33.2539 278.097 33.2539C279.716 33.2539 280.587 32.6937 280.587 31.7601V31.6979C280.587 30.7954 279.871 30.2975 276.915 29.6128C272.278 28.5547 268.699 27.2477 268.699 22.7663V22.7041C268.699 18.6584 271.904 15.7331 277.133 15.7331C280.836 15.7331 283.73 16.729 286.095 18.6273L283.077 22.8908C281.085 21.4904 278.907 20.7435 276.977 20.7435C275.514 20.7435 274.799 21.3659 274.799 22.1439V22.2062C274.799 23.202 275.546 23.6377 278.564 24.3223C283.575 25.4116 286.687 27.0298 286.687 31.1066V31.1688C286.687 35.6191 283.17 38.2643 277.88 38.2643Z" :class="$style['logo__text']" />
      <path d="M254.565 38.3265C247.843 38.3265 242.864 33.3161 242.864 27.0609V26.9987C242.864 20.7435 247.905 15.6708 254.627 15.6708C261.349 15.6708 266.329 20.6812 266.329 26.9364V26.9987C266.329 33.2539 261.287 38.3265 254.565 38.3265ZM254.627 32.8804C257.957 32.8804 260.167 30.2041 260.167 27.0609V26.9987C260.167 23.8555 257.895 21.1169 254.565 21.1169C251.266 21.1169 249.057 23.7933 249.057 26.9364V26.9987C249.057 30.1418 251.328 32.8804 254.627 32.8804Z" :class="$style['logo__text']" />
      <path d="M225.68 37.8908V16.1066H231.717V32.6004H242.267V37.8908H225.68Z" :class="$style['logo__text']" />
      <path d="M204.923 37.8908V16.1066H222.443V21.2414H210.898V24.5402H221.354V29.3016H210.898V32.756H222.599V37.8908H204.923Z" :class="$style['logo__text']" />
      <path d="M192.115 38.3265C185.33 38.3265 180.258 33.6584 180.258 27.0609V26.9987C180.258 20.6501 185.237 15.6708 191.99 15.6708C195.818 15.6708 198.525 16.8534 200.828 18.8451L197.28 23.1086C195.724 21.8016 194.168 21.0547 192.021 21.0547C188.878 21.0547 186.451 23.6999 186.451 27.0298V27.092C186.451 30.6086 188.909 33.1294 192.363 33.1294C193.826 33.1294 194.946 32.8182 195.849 32.2269V29.5817H191.554V25.1626H201.575V34.9655C199.272 36.8638 196.067 38.3265 192.115 38.3265Z" :class="$style['logo__text']" />
      <path d="M156.57 37.8909V16.1066H162.203L171.166 27.6211V16.1066H177.141V37.8909H171.85L162.545 25.9406V37.8909H156.57Z" :class="$style['logo__text']" />
      <path d="M135.813 37.8909V16.1066H153.334V21.2414H141.788V24.5402H152.245V29.3016H141.788V32.756H153.489V37.8909H135.813Z" :class="$style['logo__text']" />
      <path d="M112.138 37.8909V16.1066H118.176V24.2601H125.925V16.1066H131.962V37.8909H125.925V29.6128H118.176V37.8909H112.138Z" :class="$style['logo__text']" />
      <path d="M204.923 64.1997V42.6953H222.218V47.7642H210.821V51.0205H221.143V55.7208H210.821V59.1308H222.372V64.1997H204.923Z" :class="$style['logo__text']" />
      <path d="M191.499 64.599C185.385 64.599 181.545 61.4963 181.545 54.7685V42.6953H187.597V54.6456C187.597 57.7483 189.164 59.2229 191.56 59.2229C193.956 59.2229 195.523 57.8098 195.523 54.7992V42.6953H201.575V54.6148C201.575 61.5577 197.612 64.599 191.499 64.599Z" :class="$style['logo__text']" />
      <path d="M164.192 64.3533L155.467 42.6953H162.133L167.018 56.3045L171.903 42.6953H178.446L169.721 64.3533H164.192Z" :class="$style['logo__text']" />
      <path d="M135.468 64.1997V42.6953H152.764V47.7642H141.366V51.0205H151.689V55.7208H141.366V59.1308H152.917V64.1997H135.468Z" :class="$style['logo__text']" />
      <path d="M112.159 64.1997V42.6953H122.327C125.614 42.6953 127.888 43.5554 129.332 45.03C130.591 46.2588 131.236 47.9178 131.236 50.0375V50.0989C131.236 53.386 129.485 55.5672 126.813 56.7038L131.943 64.1997H125.061L120.73 57.6869H120.668H118.119V64.1997H112.159ZM118.119 53.0174H122.051C124.078 53.0174 125.246 52.0343 125.246 50.4676V50.4061C125.246 48.7165 124.017 47.8563 122.02 47.8563H118.119V53.0174Z" :class="$style['logo__text']" />
      <path d="M71.2772 12.7723C75.4353 28.2904 73.457 69.8932 58.3502 73.9411C43.2434 77.989 20.7287 42.949 16.5706 27.4309C12.4126 11.9128 27.9511 20.9175 43.0579 16.8696C58.1647 12.8218 67.1192 -2.74577 71.2772 12.7723Z" fill="#F7A830" />
      <path d="M95.4181 27.4433C91.2379 43.0441 68.268 78.1811 52.6671 74.0008C37.0663 69.8206 34.7423 27.9062 38.9225 12.3053C43.1027 -3.2955 52.4401 12.4449 68.0409 16.6251C83.6418 20.8053 99.5984 11.8425 95.4181 27.4433Z" fill="#A60000" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M59.2081 44.5291L57.7013 50.6785L58.0533 50.7755L58.0355 50.8482L63.3971 52.3268L64.9453 46.0052L66.4627 46.4232L68.406 53.627L74.2413 55.2343L71.9522 47.0712C73.0941 46.8828 74.063 46.4348 74.8589 45.7271C75.7181 44.9707 76.3137 43.9148 76.6458 42.5594C76.9749 41.2164 76.9512 39.9801 76.5749 38.8505C76.1985 37.7209 75.5222 36.7487 74.5461 35.934C73.5701 35.1193 72.3478 34.5097 70.8793 34.1052L62.3387 31.7528L60.2216 40.393L53.4749 38.5325L55.5689 29.9826L50.2073 28.5039L45.5765 47.4123L50.9381 48.8909L52.462 42.6687L59.2081 44.5291ZM65.923 42.013L67.0456 37.4292L68.5986 37.857C69.2298 38.0308 69.7472 38.2622 70.1509 38.5509C70.5625 38.8353 70.8452 39.1828 70.999 39.5934C71.1607 39.9997 71.1751 40.4739 71.0423 41.016C70.911 41.552 70.6822 41.9526 70.3559 42.2179C70.0361 42.4849 69.6318 42.6334 69.1432 42.6632C68.6626 42.6886 68.1067 42.6144 67.4755 42.4406L65.923 42.013Z" fill="white" />
    </svg>
  </div>
</template>
<script setup>
const { isLoading } = useLoadingIndicator({
  throttle: 200
})
</script>
<style  module>
.logo {
  display: flex;
  align-items: center;
  font-family: var(--font-family-heading);
  gap: var(--spacing-l);
  user-select: none;
  transition: all 500ms ease;
  will-change: filter, opacity;
}

.logo svg {
  width: 100%;
}

.logo__text {
  fill: var(--light-color-100);
  font-size: var(--font-size);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.logo--loading {
  filter: grayscale(1);
  opacity: 0.8;
}

</style>
