<template>
  <component
    :is="to ? nuxtLink :'button'"
    :to="to"
    :class="[$style['button'],
             {[$style[`button--${color}`]]: color},
             {[$style[`button--${size}`]]: size},
             {[$style[`button--styleless`]]: styleless},
             {[$style[`button--full-width`]]: fullWidth},
             {[$style[`button--with-border`]]: withBorder},
             {[$style[`button--transparent`]]: transparent},
             {[$style[`button--rounded`]]: rounded},
             {[$style[`button--loading`]]: loading},
             {[$style[`button--icon-only`]]: iconOnly},
    ]">
    <span :class="$style['button__content']">
      <span v-if="icon && iconPos ==='left'" :class="[$style['button__icon'], $style['button__icon--left']]">
        <Icon :name="icon" />
      </span>
      <slot />
      <span v-if="icon && iconPos === 'right'" :class="[$style['button__icon'], $style['button__icon--right']]">
        <Icon :name="icon" />
      </span>
    </span>
  </component>
</template>
<script setup>
const nuxtLink = resolveComponent('nuxt-link')
defineProps({
  to: {
    type: [String, Object],
    default: null
  },
  color: {
    type: String,
    default: null
  },
  withBorder: {
    type: Boolean,
    default: null
  },
  transparent: {
    type: Boolean,
    default: null
  },
  size: {
    type: String,
    default: null
  },
  iconPos: {
    type: String,
    default: 'left'
  },
  icon: {
    type: String,
    default: null
  },
  styleless: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  iconOnly: {
    type: Boolean,
    default: false
  },
  fullWidth: {
    type: Boolean,
    default: false
  }
})

</script>
<style  module>
.button {
    --background-color: var(--secondary-surface);
    --color: var(--secondary-on-surface);
    --content-height: var(--spacing-xxl);
    --padding-y: var(--spacing);
    --padding-x: var(--spacing-m);
    --icon-size: var(--spacing-xl);
    --font-size: --font-size-base;

    background-color: var(--background-color);
    border: 0;
    cursor: pointer;
    color: var(--color);
    display: inline-flex;
    align-items: center;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size);
    font-family: var(--font-family);
    line-height: 1;
    padding: var(--padding-y) var(--padding-x);
    vertical-align: middle;
    position: relative;
    transition: all var(--duration-micro-turbo) var(--easing-transition);
    text-decoration: none;
    font-style: normal;
    overflow: hidden;
    white-space: nowrap;
}

.button::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: var(--opacity-surface);
    z-index: 0;
    width: 100%;
    height: 100%;
    transition: transform var(--duration-micro-fast) var(--easing-transition);
    will-change: transform;
}

.button:hover {
    --background-color: var(--primary-surface-state-1);
}

.button:hover::before {
    transform: translateY(-100%) translateZ(0);
}

.button:active {
    transform: scale(.95);
}

.button:focus-visible {
    box-shadow: 0 0 0 2px var(--color);
}

.button__content {
    clip-path: view-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing);
    height: var(--content-height);
    padding: var(--padding-y) var(--padding-x);
    position: relative;
    z-index: 0;
    width: 100%;
}

.button__icon {
    display: flex;
}

.button__icon--left {
    margin-right: var(--spacing);
}

.button__icon--right {
    margin-left: var(--spacing);
}

.button__icon svg {
    margin: 0 calc(var(--spacing-s) * -1);
    width: var(--icon-size);
    height: var(--icon-size);
    stroke-width: 1.5;
}

.button--loading {
    opacity: 0.75;
}

.button--loading::before {
    top: 0;
    animation: loading 2s var(--easing-transition) infinite;
}

.button--rounded {
    border-radius: 100%;
    padding: 0;
    width: var(--spacing-xxxl);
    height: var(--spacing-xxxl);
}

.button--rounded .button__content {
    padding: 0;
    justify-content: center;
}

.button--cta {
    background: var(--cta-gradient);
    color: var(--light-color-100);
}

.button--with-border {
    box-shadow: inset 0 0 0 2px var(--text-base);
    color: var(--text-base);
}

.button--styleless {
    --padding-x: var(--spacing);
    background-color: transparent;
    font-family: var(--font-family);
    text-transform: none;
    font-weight: var(--font-weight-normal);
}

.button--styleless .button__content {
    height: auto;
}

.button--opacity {
    background-color: var(--opacity-surface);
}

.button--transparent {
    background-color: transparent;
}

.button--big {
    --content-height: var(--spacing-xxxl);
    --padding-x: var(--spacing-xxl);
    --font-size: var(--font-size-large);
}

.button--icon-only .button__icon {
    margin: 0;
}

.button--full-width {
    width: 100%;
}

@keyframes loading {
    0% {
        transform: scaleX(0);
        transform-origin: 0% 0%;
    }

    50% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
        left: 0;
    }

    100% {
        left: 100%;
    }
}

</style>
